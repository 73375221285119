<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 기계분류 -->
          <c-select
            type="search"
            codeGroupCd="HHM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="machineryClassification"
            label="기계분류"
            v-model="searchParam.machineryClassification"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="유해위험기계기구 목록"
      tableId="equipmentInsp"
      :columns="grid.columns"
      :data="grid.data"
      :selection="popupParam.type"
      :isExcelDown="false"
      :isExpan="false"
      :isFullScreen="false"
      :columnSetting="false"
      gridHeight="500px"
      rowKey="hhmHazardousMachineryId"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          </q-btn-group>
        </div>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['inspectionSubjectFlag']">
          <q-chip
            :color="setTagColor(props.row.inspectionSubjectFlag)"
            outline square
            :class="props.row.inspectionSubjectFlag ==='A' ? 'inspectionSubjectFlag-popblinking' : null"
            text-color="white"
            >
            <q-avatar v-if="props.row.inspectionSubjectFlag==='A'" icon="alarm" color="red" text-color="white" />
            {{setTagName(props.row.inspectionSubjectFlag)}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "hazard-equipment-class-pop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:80px', 
            align: "center",
            fix: true,
            sortable: true,
          },
          {
            name: "machineryClassificationName",
            field: "machineryClassificationName",
            // 대상품명
            label: "대상품명",
            style: 'width:170px', 
            align: "center",
            fix: true,
            sortable: true,
          },
          {
            name: "passNumber",
            field: "passNumber",
            label: "관리번호(기계번호)",
            style: 'width:150px', 
            type: "link",
            align: "center",
            fix: true,
            sortable: true,
          },
          {
            name: "itemNo",
            field: "itemNo",
            label: "형식번호(구간번호)",
            style: 'width:150px', 
            align: "center",
            fix: true,
            sortable: true,
          },
          {
            name: "manufacturer",
            field: "manufacturer",
            label: "제조(제작)사",
            style: 'width:150px', 
            align: "left",
            sortable: true,
          },
          {
            name: "manufactDate",
            field: "manufactDate",
            label: "제조(제작)년월",
            style: 'width:120px', 
            align: "center",
            sortable: true,
          },
          {
            name: "emplacementMonth",
            field: "emplacementMonth",
            label: "설치년월",
            style: 'width:120px', 
            align: "center",
            sortable: true,
          },
          {
            name: "esignBasisRatedLoad",
            field: "esignBasisRatedLoad",
            label: "자율검사프로그램<br>인정번호",
            style: 'width:120px', 
            align: "center",
            sortable: true,
          },
          {
            name: "validDate",
            field: "validDate",
            label: "유효기간",
            style: 'width:200px', 
            align: "center",
            sortable: true,
          },
          {
            name: "inspectionSubjectFlag",
            field: "inspectionSubjectFlag",
            label: "검사대상",
            type: 'custom',
            align: "center",
            sortable: true,
          },
          {
            name: "inspectionCycleName",
            field: "inspectionCycleName",
            // 검사주기
            label: "LBL0002511",
            align: "center",
            sortable: true,
          },
          {
            name: "lastInspectionDate",
            field: "lastInspectionDate",
            // 최근검사일
            label: "LBL0002512",
            style: 'width:100px', 
            align: "center",
            sortable: true,
          },
          {
            name: "nextInspectionDate",
            field: "nextInspectionDate",
            // 차기검사일
            label: "LBL0002513",
            style: 'width:100px', 
            align: "center",
            sortable: true,
          },
          {
            name: "remark",
            field: "remark",
            // 비고
            label: "비고",
            style: 'width:200px', 
            align: "left",
            sortable: true,
          },
          {
            name: "departmentDeptName",
            field: "departmentDeptName",
            // 담당부서
            label: "LBL0002501",
            style: 'width:150px', 
            align: "left",
            sortable: true,
          },
          {
            name: "useFlagName",
            field: "useFlagName",
            // 사용여부
            label: "LBLUSEFLAG",
            align: "center",
            style: 'width:70px', 
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        machineryClassification: null,
        inspectionSubjectFlag: null,
        resultCd: null,
        gradeCd: null,
        inspectionKindCd: null,
        inspectionCycle: null,
        departmentDeptCd: '',
        departmentUserId: '',
        startYmd: '',
        endYmd: '',
        useFlag: 'Y'
      },
      period: ['', ''],
      editable: true,
      colorItems: [
        { stepperMstCd: 'Y', stepperMstNm: '대상', colorClass: 'green' },
        { stepperMstCd: 'N', stepperMstNm: '비대상', colorClass: 'orange' },
        { stepperMstCd: 'A', stepperMstNm: '지연', colorClass: 'red' },
      ],
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  watch:{
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sop.hhm.hazard.check.list.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.check.delete.url;
      // list setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd
      }
      this.getList();
    },
    getList() {
      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "유해위험기계기구 상세"; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: row.hhmHazardousMachineryId ? row.hhmHazardousMachineryId : '',
      };
      this.popupOptions.target = () => import(`${"./hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.height = '300px';
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    onItemClick() {
      this.popupOptions.target = () => import(`${"./hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.title = '유해위험기계기구 상세'; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '유해위험기계기구를 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
  },
};
</script>
<style>
.inspectionSubjectFlag-popblinking {
  -webkit-animation:inspectionSubjectFlag-popblink 3s ease-in-out infinite alternate;
  -moz-animation:inspectionSubjectFlag-popblink 3s ease-in-out infinite alternate;
  animation:inspectionSubjectFlag-popblink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes inspectionSubjectFlag-popblink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes inspectionSubjectFlag-popblink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes inspectionSubjectFlag-popblink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>